import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function ProfilingPage({ data: { strapi: { profilingPage } }, location }) {

	return (
		<>
			<SEO title="Profiling" description={ profilingPage.seoDescription } pathname={ location.pathname } />

			<div className="content-page">
				{ profilingPage && profilingPage.content &&
					<DynamicZone components={ profilingPage.content } />
				}
			</div>
		</>
	)
}

export const query = graphql`
	query ProfilingPageQuery {
		strapi {
			profilingPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentProfilingProfilingList {
						id
						profilings {
							id
							profiling {
								id
								icon
								slug
								summary
								title
								buzzWord
							}
						}
					}
					... on StrapiQuery_ComponentProfilingProfileIntro {
						id
						heading
						stepOneIcon
						stepOneIntro
						stepThreeIcon
						stepThreeIntro
						stepTwoIcon
						stepTwoIntro
						summary
					}
					... on StrapiQuery_ComponentCommonStatement {
						id
						statement
						url
						urlText
					}
					... on StrapiQuery_ComponentCommonVideo {
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentCommonImage {
						id
						displayBorder
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonTestimonial {
						id
						introduction
						testimonial {
							slug
							thumbnail {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 400) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonContactForm {
						id
						message
					}
					... on StrapiQuery_ComponentCommonQuote {
						id
						author
						quote
					}
				}
			}
		}
	}
`